<template>
  <div class="container">

    <div class="content">
      <div class="operation">
        <div>
          <el-button type="primary" icon="el-icon-plus" @click="handleCreate">新建</el-button>
          <el-button type="danger" plain @click="batchDelete">删除</el-button>
        </div>
        <el-input
          v-model="search"
          style="width: 400px;"
          @keyup.enter.native="handleSearch"
          placeholder="请输入电话号码、昵称、ID或角色"
        />
      </div>
      <el-table
        ref="multipleTable"
        :data="users"
        style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" />
        <el-table-column
          prop="firstName"
          label="昵称"
        >
        </el-table-column>
        <el-table-column
          prop="id"
          label="ID"
        >
        </el-table-column>
        <el-table-column
          prop="login"
          label="手机号码"
        >
        </el-table-column>
        <el-table-column
          prop="roleList"
          label="角色"
        >
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.roleList" :key="item.id">{{ item.roleName }}<span v-if="index !== (scope.row.roleList.length - 1)">，</span></span>
          </template>
        </el-table-column>
        <el-table-column
          prop="enable"
          label="显示"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.activated"
              @change="v => handleActiveChange(v, scope.row.id)"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="创建日期"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="operation"
          min-width="100"
          label="操作"
        >
          <template slot-scope="scope">
              <el-button type="text" @click="edit(scope.row)">编辑</el-button>
              <el-button type="text" @click="deleteOne(scope.row.id)" v-show="!isAdmin(scope.row.firstName)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageOptions"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>

    <EditContent
      :type="type"
      :data="selectData"
      :visible="showEdit"
      :roles="roles"
      @onConfirmEdit="handleConfirmEdit"
      @onConfirmCreate="handleConfirmCreate"
      @onCancel="showEdit = false"
    />
  </div>
</template>

<script>
import * as R from 'ramda'
import { pageOptions } from '@/utils/config'
import * as userService from '@/api/user'
import * as roleService from '@/api/role'
import EditContent from './edit'

export default {
  components: {
    EditContent
  },
  data () {
    return {
      search: '',
      type: 'create',
      users: [],
      roles: [],
      selected: [],
      total: 10,
      pageOptions: pageOptions,
      pageSize: 10,
      currentPage: 1,
      showEdit: false,
      selectData: null
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData (page = 1, size = 10, search = '') {
      try {
        const res = await userService.list({ page: page - 1, size, name: search })
        const roleData = await roleService.listNoPage()
        this.users = res.list
        this.total = res.totalElements
        this.roles = roleData
      } catch (err) {
      }
    },
    getItem (items) {
      return items.map(o => o.name).join(',')
    },
    handleCreate () {
      this.type = 'create'
      this.showEdit = true
      this.selectData = null
    },
    handleSelectionChange (val) {
      this.selected = val
    },
    isAdmin (name) {
      return R.contains('admin', R.toLower(name))
    },
    handleSearch () {
      this.fetchData(this.currentPage, this.pageSize, this.search)
    },
    edit (data) {
      this.type = 'edit'
      this.showEdit = true
      this.selectData = R.clone(data)
    },
    async deleteOne (id) {
      try {
        await userService.deleteById(id)
        this.fetchData()
        this.$notify.success({ title: '删除用户成功' })
      } catch (err) {
        this.$notify.error({ title: '删除用户发生错误' })
      }
    },
    async batchDelete () {
      try {
        const selected = this.selected.filter(o => !this.isAdmin(o.firstName))
        await Promise.all(selected.map(o => this.deleteOne(o.id)))
        this.fetchData()
        this.$notify.success({ title: '删除用户成功' })
      } catch (err) {
        this.$notify.error({ title: '删除用户失败' })
      }
    },
    async handleConfirmEdit (data) {
      console.log('data', data)
      try {
        await userService.update({ ...data, id: this.selectData.id })
        this.fetchData()
        this.$notify.success({ title: '编辑用户成功' })
      } catch (err) {
        this.$notify.error({ title: '编辑用户失败' })
      }
    },
    async handleConfirmCreate (data) {
      try {
        await userService.create(data)
        this.showEdit = false
        this.fetchData()
        this.$notify.success({ title: '创建用户成功' })
      } catch (err) {
        this.$notify.error({ title: '创建用户失败' })
      }
    },
    async handleActiveChange (val, id) {
      try {
        if (val) {
          await userService.enable(id)
        } else {
          await userService.disabled(id)
        }
        this.$notify.success({ title: '更新成功' })
        this.fetchData()
      } catch (err) {
        this.$notify.error({ title: '更新显示失败' })
      }
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.fetchData(this.currentPage, val)
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchData(val, this.pageSize)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  text-align: left;
}

.content {
  .operation {
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
  margin-top: 10px;
  background: #FFFFFF;
  padding: 10px;
}
.pagination {
  margin-top: 30px;
  text-align: center;
}

</style>
