<template>
  <el-dialog
    :title="type === 'create' ? '创建用户' : '编辑'"
    :visible.sync="visible"
    :modal-append-to-body="false"
    :before-close="cancel"
    width="600px"
  >
    <field label="用户昵称：" :asterisk="true" labelWidth="81px" :validation="validation.firstName" >
      <el-input v-model="formData.firstName" placeholder="请输入用户昵称"></el-input>
    </field>
    <field label="手机号码：" :asterisk="true" labelWidth="81px" :validation="validation.login" >
      <el-input v-model="formData.login" placeholder="请输入手机号码" maxlength="11"></el-input>
    </field>
    <field label="用户角色：" :asterisk="true" labelWidth="81px" :validation="validation.roleList" >
      <el-select v-model="formData.roleList" placeholder="请选择角色权限" style="width: 100%" multiple>
        <el-option
          v-for="item in roles"
          :key="item.id"
          :label="item.roleName"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </field>
    <field label="密码：" :asterisk="true" labelWidth="81px" >
      <el-input v-model="formData.password"></el-input>
    </field>
    <field label="是否可用：" :asterisk="true" labelWidth="81px" :validation="validation.activated" >
      <el-radio-group v-model="formData.activated">
        <el-radio :label="1">是</el-radio>
        <el-radio :label="2">否</el-radio>
      </el-radio-group>
    </field>
    <div class="footer">
      <el-button type="primary" @click="confirm">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import * as v from '@/utils/validator'
import validation from '@/mixins/validation'
import * as R from 'ramda'
const init = {
  firstName: '',
  login: '',
  roleList: [],
  activated: 1,
  password: ''
}

export default {
  naem: 'editRole',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'create'
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    roles: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      formData: R.clone(init),
      warehouses: []
    }
  },
  mixins: [validation({
    rules: {
      firstName: [v.required('请输入用户昵称')],
      login: [v.required('请输入手机号码'), v.isTelephone()],
      roleList: [v.required('请选择角色')],
      activated: [v.required('请选择是否可用')]
    },
    field: 'formData'
  })],
  methods: {
    cancel () {
      this.$emit('onCancel')
      this.formData = R.clone(init)
    },
    async confirm () {
      try {
        await this.$validate()
        const postData = R.clone(this.formData)
        postData.activated = this.formData.activated === 1
        if (this.type === 'create') {
          this.$emit('onConfirmCreate', postData)
        } else {
          this.$emit('onConfirmEdit', postData)
        }
        this.cancel()
      } catch (err) {
        console.log('err', err)
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler: function (curVal, oldVal) {
        if (!R.isNil(curVal)) {
          this.formData = {
            firstName: curVal.firstName,
            login: curVal.login,
            roleList: curVal.roleList.map(o => o.id),
            activated: curVal.activated ? 1 : 2,
            password: ''
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  border-top: 1px solid #D9E0F0FF;
  padding-top: 30px;
  text-align: center;
}

</style>
